<template>
    <v-container>
        <v-row>
            <v-col cols="12" v-if="blocked && !loading">
                <h1>Diese Seite kann nicht angezeigt werden</h1>
            </v-col>    
            <v-col cols="12" v-if="!blocked && loading">
                <v-progress-circular indeterminate size="80"></v-progress-circular>
            </v-col>
            <v-col cols="12" v-if="!blocked && !loading">
                <h1>Sportangebot einreichen</h1>
                <v-row class="mt-4">
                    <v-col cols="12">
                        <v-card light class="rounded-xl pa-4">
                            <v-row>
                                <v-col cols="12" md order="2" order-md="2">
                                    <v-text-field
                                        filled
                                        rounded
                                        label="Titel des Angebots"
                                        v-model="sportangebot.name"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="auto" order="1" order-md="1">
                                    <v-row>
                                        <v-col cols="auto" align-self="center">
                                            <v-avatar>
                                                <v-img contain :src="verein.logo"></v-img>
                                            </v-avatar>
                                        </v-col>
                                        <v-col class="pl-0" align-self="center">
                                            <small>Verein</small>
                                            <h3>{{ verein.name }}</h3>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="12" order="3">
                                    <v-textarea
                                        filled
                                        rounded
                                        hide-details
                                        label="Beschreibung"
                                        v-model="sportangebot.beschreibung"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" lg="4" order="3">
                                    <v-select
                                        :items="sportarten.data"
                                        item-value="id"
                                        item-text="name"
                                        offset-y
                                        filled
                                        rounded
                                        hide-details
                                        label="Sportart"
                                        v-model="sportangebot.sportart"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" lg="4" order="3">
                                    <v-select
                                        :items="altersgruppen"
                                        offset-y
                                        filled
                                        rounded
                                        hide-details
                                        multiple
                                        label="Altersgruppe(n)"
                                        v-model="sportangebot.altersgruppen"
                                        
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" lg="4" order="3">
                                    <v-select
                                        :items="geschlecht"
                                        offset-y
                                        filled
                                        rounded
                                        hide-details
                                        label="Geschlecht"
                                        prepend-inner-icon="mdi-gender-male-female"
                                        v-model="sportangebot.geschlecht"
                                        
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" lg="4" order="3">
                                    <v-select
                                        :items="teilnehmerzahl"
                                        offset-y
                                        filled
                                        rounded
                                        hide-details
                                        label="Maximale Teilnehmerzahl"
                                        prepend-inner-icon="mdi-account-group"
                                        v-model="sportangebot.teilnehmerzahl"
                                        
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-5" justify="center">
                    <v-col cols="12">
                        <v-card light class="rounded-xl pa-4">
                            <v-row>
                                <v-col cols="12">
                                    <h3>Medien</h3>
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                lg="4"
                                align-self="center"
                                v-for="(media, index) in sportangebot.fotos"
                                :key="index"
                                >
                                    <v-card
                                        color="secondary"
                                        class="rounded-xl pa-0 ma-4"
                                        style="min-height: 200px;"
                                    >
                                        <v-row justify="center" style="height: 100%;">
                                        <v-col cols="6" align-self="center" v-if="media.img">
                                            <v-img :src="media.img" width="100%"></v-img>
                                        </v-col>
                                        <v-col cols="8" align-self="center">
                                            <v-btn
                                                block
                                                rounded
                                                :text="!media.img"
                                                :color="media.img ? 'primary' : 'white'"
                                                @click.stop="media.dialog = true"
                                            >
                                            <v-icon>
                                                {{ media.img ? 'mdi-change' : 'mdi-upload' }}
                                            </v-icon>
                                            {{ media.img ? 'BILD ÄNDERN' : 'BILD HOCHLADEN' }}
                                            </v-btn>
                                        </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-dialog
                                        v-model="media.dialog"
                                        :max-width="$vuetify.breakpoint.mdAndUp ? '1200' : 'none'"
                                        :overlay-opacity="
                                        $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
                                        "
                                        :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
                                        :transition="
                                        $vuetify.breakpoint.mdAndUp
                                            ? 'dialog-transition'
                                            : 'dialog-bottom-transition'
                                        "
                                        :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
                                        :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
                                        :content-class="$vuetify.breakpoint.mdAndUp? 'rounded-xl' : 'rounded-0'"
                                    >
                                        <v-card :class="$vuetify.breakpoint.mdAndUp? 'rounded-xl' : 'rounded-0'">
                                        <v-toolbar color="primary" dark>
                                            <v-btn icon @click="closeDialog(index)">
                                            <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>Bild hochladen</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                            <v-btn
                                                dark
                                                text
                                                :loading="media.uploading"
                                                @click="upload(index)"
                                            >
                                                Speichern
                                            </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-row justify="center" class="my-4">
                                            <v-col cols="12">
                                                <v-image-input
                                                    v-model="media.imageData"
                                                    :image-quality="0.8"
                                                    clearable
                                                    accept="image/*"
                                                    :hideActions="media.imageData ? false : true"
                                                    :imageWidth="$vuetify.breakpoint.mdAndUp ? 640 : 320"
                                                    :imageMinScaling="'contain'"
                                                    :imageHeight="$vuetify.breakpoint.mdAndUp ? 360 : 180"
                                                    :full-width="true"
                                                    image-format="png"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="text-center"
                                                v-if="!media.imageData"
                                            >
                                                Für eine gute Darstellung wähle bitte ein Bild mit
                                                mindestens 640 x 360 Pixeln.
                                            </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions right>
                                            <v-btn
                                            color="primary"
                                            right
                                            rounded
                                            :loading="media.uploading"
                                            @click="upload(index)"
                                            >
                                                Bild hochladen
                                            </v-btn>
                                            <v-btn
                                            color="secondary"
                                            right
                                            rounded
                                            @click="closeDialog(index)"
                                            >
                                                Abbrechen
                                            </v-btn>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" class="text-center" v-if="sportangebot.fotos.length == 0">
                                    <v-btn
                                        rounded
                                        color="primary"
                                        @click="
                                        sportangebot.fotos.push({
                                            imageData: '',
                                            titelbild: false,
                                            uploading: false,
                                            dialog: true,
                                            img: '',
                                        })
                                        "
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        Bild Hinzufügen
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-5" justify="center">
                    <v-col cols="12">
                        <v-card light class="rounded-xl pa-4">
                        <v-row>
                            <v-col cols="12">
                            <h3>Zeitraum</h3>
                            </v-col>
                            <v-col cols="12">
                            <v-select
                                filled
                                rounded
                                label="Wiederholung"
                                :items="repeat"
                                v-model="sportangebot.wiederholung"
                                hide-details
                                
                            ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            v-if="sportangebot.wiederholung == 'Einmaliger Termin'"
                            >
                            <v-row v-if="sportangebot.termin">
                                <v-col cols="12">
                                     <v-menu
                                        v-model="menu.einmalig.datum"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="parseDate(sportangebot.termin.datum)"
                                                label="Datum"
                                                prepend-inner-icon="mdi-calendar"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="sportangebot.termin.datum"
                                            locale="de"
                                            first-day-of-week="1"
                                            @input="menu.einmalig.datum = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" lg>
                                     <v-menu
                                        v-model="menu.einmalig.beginn"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="sportangebot.termin.beginn"
                                                label="Beginn"
                                                prepend-inner-icon="mdi-clock"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="sportangebot.termin.beginn"
                                            format="24hr"
                                            @input="menu.einmalig.beginn = false"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                cols="auto"
                                v-if="$vuetify.breakpoint.lgAndUp"
                                align-self="center"
                                >
                                    bis
                                </v-col>
                                <v-col cols="12" lg>
                                    <v-menu
                                        v-model="menu.einmalig.ende"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="sportangebot.termin.ende"
                                                label="Ende"
                                                prepend-inner-icon="mdi-clock"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="sportangebot.termin.ende"
                                            format="24hr"
                                            @input="menu.einmalig.ende = false"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            </v-col>
                            <v-col
                            cols="12"
                            v-if="sportangebot.wiederholung == 'Mehrere Termine'"
                            >
                            <v-row
                                v-for="(termin, index) in sportangebot.termine"
                                :key="index"
                            >
                                <v-col cols="12" class="font-weight-bold">
                                <v-row justify="space-between">
                                    <v-col cols="auto">Termin {{ index + 1 }}:</v-col>
                                    <v-col cols="auto" v-if="sportangebot.termine.length > 2">
                                        <v-btn
                                            icon
                                            @click="removeTermin(index)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        v-model="menu.mehrere[index].datum"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="parseDate(termin.datum)"
                                                label="Datum"
                                                prepend-inner-icon="mdi-calendar"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="termin.datum"
                                            locale="de"
                                            first-day-of-week="1"
                                            @input="menu.mehrere[index].datum = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" lg>
                                    <v-menu
                                        v-model="menu.mehrere[index].beginn"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="termin.beginn"
                                                label="Beginn"
                                                prepend-inner-icon="mdi-clock"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="termin.beginn"
                                            format="24hr"
                                            @input="menu.mehrere[index].beginn = false"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                cols="auto"
                                v-if="$vuetify.breakpoint.lgAndUp"
                                align-self="center"
                                >
                                    bis
                                </v-col>
                                <v-col cols="12" lg>
                                    <v-menu
                                        v-model="menu.mehrere[index].ende"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="termin.ende"
                                                label="Ende"
                                                prepend-inner-icon="mdi-clock"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="termin.ende"
                                            format="24hr"
                                            @input="menu.mehrere[index].ende = false"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="auto">
                                <v-btn
                                    color="primary"
                                    rounded
                                    @click="
                                        sportangebot.termine.push({
                                            datum: '',
                                            beginn: '',
                                            ende: '',
                                        })
                                        menu.mehrere.push({
                                            datum: false,
                                            beginn: false,
                                            ende: false
                                        })
                                    "
                                >
                                    <v-icon>mdi-plus</v-icon>
                                    Termin hinzufügen
                                </v-btn>
                                </v-col>
                            </v-row>
                            </v-col>
                            <v-col
                            cols="12"
                            v-if="sportangebot.wiederholung == 'Regelmäßige Termine'"
                            >
                            <v-row
                                v-for="(termin, index) in sportangebot.zeitraum"
                                :key="index"
                            >
                                <v-col cols="12" class="font-weight-bold">
                                <v-row justify="space-between">
                                    <v-col cols="auto">Termin {{ index + 1 }}:</v-col>
                                    <v-col cols="auto" v-if="sportangebot.zeitraum.length > 1">
                                        <v-btn
                                            icon
                                            @click="removeZeitraum(index)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                </v-col>
                                <v-col cols="12">
                                <v-select
                                    filled
                                    rounded
                                    hide-details
                                    label="Wochentag"
                                    v-model="termin.wochentag"
                                    :items="[
                                    'Montag',
                                    'Dienstag',
                                    'Mittwoch',
                                    'Donnerstag',
                                    'Freitag',
                                    'Samstag',
                                    'Sonntag',
                                    ]"
                                    prepend-inner-icon="mdi-calendar"
                                    
                                ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        v-model="menu.regelmaessig[index].vondatum"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="parseDate(termin.datum.von)"
                                                label="Von Datum"
                                                prepend-inner-icon="mdi-calendar"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="termin.datum.von"
                                            locale="de"
                                            first-day-of-week="1"
                                            @input="menu.regelmaessig[index].vondatum = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        v-model="menu.regelmaessig[index].bisdatum"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="parseDate(termin.datum.bis)"
                                                label="Bis Datum"
                                                prepend-inner-icon="mdi-calendar"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="termin.datum.bis"
                                            locale="de"
                                            first-day-of-week="1"
                                            @input="menu.regelmaessig[index].bisdatum = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" lg>
                                    <v-menu
                                        v-model="menu.regelmaessig[index].beginn"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="termin.uhrzeit.beginn"
                                                label="Beginn"
                                                prepend-inner-icon="mdi-clock"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="termin.uhrzeit.beginn"
                                            format="24hr"
                                            @input="menu.regelmaessig[index].beginn = false"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                cols="auto"
                                v-if="$vuetify.breakpoint.lgAndUp"
                                align-self="center"
                                >
                                bis
                                </v-col>
                                <v-col cols="12" lg>
                                    <v-menu
                                        v-model="menu.regelmaessig[index].ende"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="termin.uhrzeit.ende"
                                                label="Ende"
                                                prepend-inner-icon="mdi-clock"
                                                filled
                                                rounded
                                                hide-details
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="termin.uhrzeit.ende"
                                            format="24hr"
                                            @input="menu.regelmaessig[index].ende = false"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-btn
                                        color="primary"
                                        rounded
                                        @click="
                                            sportangebot.zeitraum.push({
                                                wochentag: '',
                                                datum: {
                                                    von: '',
                                                    bis: '',
                                                },
                                                uhrzeit: {
                                                    beginn: '',
                                                    ende: '',
                                                }
                                            })
                                            menu.regelmaessig.push({
                                                vondatum: false,
                                                bisdatum: false,
                                                beginn: false,
                                                ende: false
                                            })
                                        "
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                        Termin hinzufügen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            </v-col>
                        </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-5" justify="center">
                    <v-col cols="12">
                        <v-card light class="rounded-xl pa-4">
                        <v-row>
                            <v-col cols="12">
                                <h3>Veranstaltungsort</h3>
                            </v-col>
                            <v-col cols="12" v-if="sportangebot.veranstaltungsort">
                            <v-text-field
                                filled
                                rounded
                                label="Name des Veranstaltungsortes (z.B. Emssporthalle)"
                                prepend-inner-icon="mdi-office-building-marker"
                                v-model="sportangebot.veranstaltungsort.name"
                                hide-details
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="sportangebot.veranstaltungsort">
                            <v-text-field
                                filled
                                rounded
                                label="Adresse Veranstaltungsortes (Straße + Nr.)"
                                prepend-inner-icon="mdi-map-marker"
                                v-model="sportangebot.veranstaltungsort.adresse"
                                hide-details
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" v-if="sportangebot.veranstaltungsort">
                            <v-text-field
                                filled
                                rounded
                                label="Postleitzahl"
                                prepend-inner-icon="mdi-map-marker"
                                v-model="sportangebot.veranstaltungsort.plz"
                                hide-details
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="8" v-if="sportangebot.veranstaltungsort">
                            <v-text-field
                                filled
                                rounded
                                label="Ort"
                                prepend-inner-icon="mdi-home-map-marker"
                                v-model="sportangebot.veranstaltungsort.ort"
                                hide-details
                                
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-5" justify="center">
                <v-col
                    cols="12"
                    v-for="(ansprechpartner, index) in sportangebot.ansprechpartner"
                    :key="index"
                >
                    <v-card light class="rounded-xl pa-4">
                    <v-row>
                        <v-col cols="12">
                        <v-row>
                            <v-col>
                            <h3>
                                {{ index > 0 ? index + 1 + '. ' : '' }}Ansprechpartner
                            </h3>
                            </v-col>
                            <v-col cols="auto">
                            <v-btn
                                icon
                                color="danger"
                                v-if="sportangebot.ansprechpartner.length > 1"
                                @click="removeAnsprechpartner(index)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                        </v-col>
                        <v-col cols="12" lg="6">
                        <v-text-field
                            rounded
                            filled
                            v-model="ansprechpartner.vorname"
                            label="Vorname"
                            hide-details
                            
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                        <v-text-field
                            rounded
                            filled
                            v-model="ansprechpartner.nachname"
                            label="Nachname"
                            hide-details
                            
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                        <v-text-field
                            rounded
                            filled
                            v-model="ansprechpartner.funktion"
                            label="Funktion (z.B. Trainer | optional)"
                            hide-details
                            
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                        <v-text-field
                            rounded
                            filled
                            v-model="ansprechpartner.email"
                            label="E-Mail"
                            hide-details
                            
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                                rounded
                                filled
                                v-model="ansprechpartner.telefon"
                                label="Telefon (optional)"
                                hide-details
                                
                            ></v-text-field>
                            <v-checkbox color="primary" hide-details v-model="ansprechpartner.telefonpublic" label="Telefonnummer darf öffentlich angezeigt werden"></v-checkbox>
                            <v-checkbox v-if="ansprechpartner.telefonpublic" color="primary" hide-details v-model="ansprechpartner.whatsapp" label="Telefonnummer kann per WhatsApp kontaktiert werden"></v-checkbox>
                        </v-col>
                        <v-col
                        cols="12"
                        v-if="index + 1 == sportangebot.ansprechpartner.length"
                        class="text-center"
                        >
                        <v-btn
                            rounded
                            color="primary"
                            @click="
                            sportangebot.ansprechpartner.push({
                                vorname: '',
                                nachname: '',
                                telefon: '',
                                telefonpublic: false,
                                whatsapp: false,
                                email: '',
                                emailvalid: false,
                                funktion: '',
                            })
                            "
                        >
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Weiterer Ansprechpartner
                        </v-btn>
                        </v-col>
                    </v-row>
                    </v-card>
                </v-col>
                </v-row>
                <v-row class="mt-5" justify="center">
                <v-col cols="12">
                    <v-card light class="rounded-xl pa-4">
                    <v-row>
                        <v-col cols="12">
                        <h3>
                            Material, das Teilnehmer mitbringen müssen
                        </h3>
                        </v-col>
                        <v-col cols="12">
                        <v-row
                            justify="center"
                            v-for="(material, index) in sportangebot.material"
                            :key="index"
                        >
                            <v-col cols="2" align-self="center" class="primary--text text-center">
                                <v-icon color="primary" v-if="material.iconset == 'mdi'" large>mdi-{{material.icon}}</v-icon>
                                <font-awesome-icon v-if="material.iconset == 'fa'" size="lg" :icon="material.icon" />   
                            </v-col>
                            <v-col align-self="center">
                                <v-text-field
                                    filled
                                    rounded
                                    label="Bezeichnung"
                                    v-model="material.name"
                                    hide-details
                                    
                                ></v-text-field>
                            </v-col>
                            <v-col cols="auto" align-self="center">
                                <v-btn icon @click="removeMaterial(index)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn
                                color="primary"
                                rounded
                                @click="addMaterial = true"
                            >
                                <v-icon>mdi-plus</v-icon>
                                Hinzufügen
                            </v-btn>
                        </v-col>
                        <v-dialog content-class="rounded-xl" v-model="addMaterial">
                            <v-card class="rounded-xl">
                                <v-row justify="space-around" class="pa-5">
                                    <v-col cols="12" class="text-center">
                                        <h2>Wähle eine Vorlage aus</h2>
                                    </v-col>
                                    <v-col v-for="m in material" :key="m.name" cols="6" md="3">
                                        <v-card 
                                            color="#b6b4c8" 
                                            hover 
                                            class="rounded-xl primary--text text-center pa-4" 
                                            @click="
                                                sportangebot.material.push(m)
                                                addMaterial = false
                                            ">
                                            <v-icon x-large color="primary" v-if="m.iconset == 'mdi'">mdi-{{m.icon}}</v-icon><br v-if="m.iconset == 'mdi'">
                                            <font-awesome-icon v-if="m.iconset == 'fa'" size="xl" :icon="m.icon" /><br v-if="m.iconset == 'fa'">
                                            <h3 class="my-2">{{m.name}}</h3>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-dialog> 
                    </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" class="mt-5 text-center">
                    <v-btn x-large rounded color="success" :disabled="!valid" :loading="creating" @click="createSportangebot()">
                        <font-awesome-icon size="lg" class="mr-2" icon="fa-solid fa-paper-plane-top" /> Angebot einreichen
                    </v-btn>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="success" content-class="rounded-xl" overlay-opacity="0.85" max-width="650">
            <v-card class="rounded-xl primary--text text-center pa-4">
                <font-awesome-icon class="mt-2" size="7x" icon="fa-duotone fa-circle-check" />
                <h2 class="my-4">Sportangebot wurde eingereicht</h2>
                <p class="mb-4">Die eingetragenen Ansprechpartner müssen nun ihre E-Mail-Adressen bestätigen. Anschließend wird das Sportangebot von einem Mitarbeiter geprüft.</p>
                <v-btn rounded color="primary" @click="new_angebot()">Weiteres Angebot einreichen</v-btn>
                <v-btn text rounded class="my-2" color="secondary" to="/">Fertig</v-btn>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore'

export default {
    name: 'Sportangebot-einreichen',
    data(){
        return {
            blocked: false,
            success: false,
            creating: false,
            addMaterial: false,
            sportangebot: {
                name: '',
                verein: '',
                beschreibung: '',
                sportart: '',
                veranstaltungsort: {
                    name: '',
                    adresse: '',
                    plz: '',
                    ort: '',
                },
                altersgruppen: [],
                geschlecht: [],
                teilnehmerzahl: '',
                termin: { datum: '', beginn: '', ende: '' },
                termine: [{ datum: '', beginn: '', ende: '' }, { datum: '', beginn: '', ende: '' }],
                zeitraum: [
                    {
                        wochentag: '',
                        datum: { von: '', bis: '' },
                        uhrzeit: { beginn: '', ende: '' },
                    },
                ],
                wiederholung: 'Einmaliger Termin',
                material: [],
                ansprechpartner: [
                {
                    vorname: '',
                    nachname: '',
                    funktion: '',
                    telefon: '',
                    telefonpublic: false,
                    whatsapp: false,
                    email: '',
                    emailvalid: false
                },
                ],
                fotos: [],
                sonstiges: '',
                created_at: '',
                created: false,
            },
            altersgruppen: [
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
            ],
            material: [
                {
                    name: 'Sportschuhe',
                    icon: 'shoe-sneaker',
                    iconset: 'mdi'
                },
                {
                    name: 'Hallenschuhe',
                    icon: 'shoe-sneaker',
                    iconset: 'mdi'
                },
                {
                    name: 'Laufschuhe',
                    icon: 'shoe-sneaker',
                    iconset: 'mdi'
                },
                {
                    name: 'Fussballschuhe',
                    icon: 'shoe-cleat',
                    iconset: 'mdi'
                },
                {
                    name: 'Sportshirt',
                    icon: 'fa-light fa-shirt',
                    iconset: 'fa'
                },
                {
                    name: 'Sportjacke',
                    icon: 'fa-solid fa-shirt-long-sleeve',
                    iconset: 'fa'
                },
                {
                    name: 'Pullover',
                    icon: 'fa-solid fa-shirt-long-sleeve',
                    iconset: 'fa'
                },
                {
                    name: 'Sporthose',
                    icon: 'fa-light fa-clothes-hanger',
                    iconset: 'fa'
                },
                {
                    name: 'Schwimmkleidung',
                    icon: 'fa-light fa-clothes-hanger',
                    iconset: 'fa'
                },
                {
                    name: 'Handtuch',
                    icon: 'fa-light fa-rectangle-vertical',
                    iconset: 'fa'
                },
                {
                    name: 'Maske',
                    icon: 'fa-light fa-mask-face',
                    iconset: 'fa'
                },
                {
                    name: 'Trinkflasche',
                    icon: 'fa-regular fa-droplet',
                    iconset: 'fa'
                },
                {
                    name: 'Tischtennis-Schläger',
                    icon: 'fa-light fa-table-tennis-paddle-ball',
                    iconset: 'fa'
                },
                {
                    name: 'Tennis-Schläger',
                    icon: 'fa-light fa-racquet',
                    iconset: 'fa'
                },
                {
                    name: 'Badminton-Schläger',
                    icon: 'fa-light fa-badminton',
                    iconset: 'fa'
                },
                {
                    name: 'Weitere',
                    icon: 'fa-light fa-plus',
                    iconset: 'fa'
                }
            ],
            teilnehmerzahl: [],
            repeat: ['Einmaliger Termin', 'Mehrere Termine', 'Regelmäßige Termine'],
            geschlecht: ['Nur für Jungen', 'Nur für Mädchen', 'Für Alle'],
            verein: '',
            loading: true,
            menu: {
                einmalig: {
                  datum: false,
                  beginn: false,
                  ende: false  
                },
                regelmaessig: [
                    {
                        vondatum: false,
                        bisdatum: false,
                        beginn: false,
                        ende: false
                    }
                ],
                mehrere: [
                    {
                        datum: false,
                        beginn: false,
                        ende: false
                    },
                    {
                        datum: false,
                        beginn: false,
                        ende: false
                    }
                ]
            }
            
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            vereine: 'vereine',
            sportarten: 'sportarten',
            sportangebote: 'sportangebote',
            db: 'db',
        }),
        valid(){
            if(this.sportangebot.name
                && this.sportangebot.beschreibung
                && this.sportangebot.sportart
                && this.sportangebot.teilnehmerzahl
                && this.sportangebot.altersgruppen.length > 0
                && this.sportangebot.sportart
                && this.sportangebot.veranstaltungsort.name
                && this.sportangebot.veranstaltungsort.adresse
                && this.sportangebot.veranstaltungsort.plz
                && this.sportangebot.veranstaltungsort.ort
                && this.sportangebot.geschlecht
            ){
                if(this.sportangebot.ansprechpartner.filter(ap => !ap.vorname || !ap.nachname || !ap.email ).length == 0){
                    if(this.sportangebot.wiederholung == 'Mehrere Termine'){
                        if(this.sportangebot.termine.filter(termin => !termin.datum || !termin.beginn || !termin.ende).length == 0){
                            return true
                        }
                    }
                    else if(this.sportangebot.wiederholung == 'Regelmäßige Termine'){
                        if(this.sportangebot.zeitraum.filter(zeitraum => !zeitraum.datum?.von || !zeitraum.datum?.bis || !zeitraum.uhrzeit?.beginn || !zeitraum.uhrzeit?.ende).length == 0){
                            return true
                        }
                    }
                    else {
                        return true
                    }
                }
            }
            return false 
        }
    },
    async mounted() {
        this.teilnehmerzahl = []
        for (var i = 1; i <= 100; i++) {
            this.teilnehmerzahl.push(i)
        }

        if(this.$route.params.verein){
            const vereinDoc = doc(this.db, 'Vereine', this.$route.params.verein)
            const vereinSnap = await getDoc(vereinDoc)

            if(vereinSnap.exists()){
                this.verein = vereinSnap.data()
                this.verein.id = vereinSnap.id
                this.sportangebot.verein = this.verein.id
            }
            else {
                this.blocked = true
            }
            this.loading = false
        }
        else {
            this.blocked = true
            this.loading = false
        }

    },
    methods: {
        removeTermin(id) {
            var neue_termine = []
            this.sportangebot.termine.forEach((termin, index) => {
                if (index != id) {
                    neue_termine.push(termin)
                }
            })
            this.sportangebot.termine = neue_termine

            var neues_menu = []
            this.menu.mehrere.forEach((menu, index) => {
                if(index != id){
                    neues_menu.push(menu)
                }
            })
            this.menu.regelmaessig = neues_menu
        },
        removeZeitraum(id) {
            var neuer_zeitraum = []
            this.sportangebot.zeitraum.forEach((termin, index) => {
                if (index != id) {
                neuer_zeitraum.push(termin)
                }
            })
            this.sportangebot.zeitraum = neuer_zeitraum

            var neues_menu = []
            this.menu.regelmaessig.forEach((menu, index) => {
                if(index != id){
                    neues_menu.push(menu)
                }
            })
            this.menu.regelmaessig = neues_menu
        },
        removeAnsprechpartner(id) {
            var neue_ansprechpartner = []
            this.sportangebot.ansprechpartner.forEach((person, index) => {
                if (index != id) {
                neue_ansprechpartner.push(person)
                }
            })
            this.sportangebot.ansprechpartner = neue_ansprechpartner
        },
        removeMaterial(id) {
            var neues_material = []
            this.sportangebot.material.forEach((material, index) => {
                if (index != id) {
                neues_material.push(material)
                }
            })
            this.sportangebot.material = neues_material
        },
        closeDialog(index) {
            this.sportangebot.fotos[index].dialog = false
            this.sportangebot.fotos[index].uploading = false
            this.sportangebot.fotos[index].imageData = ''
            if(!this.sportangebot.fotos[index].img){
                this.sportangebot.fotos = []
            }
        },
        upload(index) {
            if (this.sportangebot.fotos[index].imageData) {
                this.sportangebot.fotos[index].img = this.sportangebot.fotos[index].imageData
                this.sportangebot.fotos[index].uploading = false
                this.sportangebot.fotos[index].imageData = ''
                this.sportangebot.fotos[index].dialog = false
            }
        },
        parseDate(date){
            if(date){
                var datum = date.split('-')
                if(datum.length == 3){
                    return datum[2]+'.'+datum[1]+'.'+datum[0]
                }
            }
            return date
        },
        async createSportangebot(){
            this.creating = true
            this.success = false
            try {
                const angebotRef = await addDoc(collection(this.db, 'Sportangebote'), {
                    name: this.sportangebot.name,
                    verein: this.sportangebot.verein,
                    vereinsname: this.verein.name || '',
                    beschreibung: this.sportangebot.beschreibung,
                    sportart: this.sportangebot.sportart,
                    veranstaltungsort: this.sportangebot.veranstaltungsort,
                    altersgruppen: this.sportangebot.altersgruppen,
                    geschlecht: this.sportangebot.geschlecht,
                    teilnehmerzahl: this.sportangebot.teilnehmerzahl,
                    termin: this.sportangebot.termin,
                    termine: this.sportangebot.termine,
                    zeitraum: this.sportangebot.zeitraum,
                    wiederholung: this.sportangebot.wiederholung,
                    material: this.sportangebot.material,
                    ansprechpartner: this.sportangebot.ansprechpartner,
                    fotos: this.sportangebot.fotos,
                    created_at: serverTimestamp(),
                    approved: false,
                    active: false
                })
                this.success = true
                this.creating = false
            } catch(e) {
                console.log(e)
                this.error = "Fehler beim einreichen des Sportangebots: " + e
                this.creating = false
            }
        },
        new_angebot(){
            this.sportangebot = {
                name: '',
                verein: this.verein.id,
                beschreibung: '',
                sportart: '',
                veranstaltungsort: {
                    name: '',
                    adresse: '',
                    plz: '',
                    ort: '',
                },
                altersgruppen: [],
                geschlecht: [],
                teilnehmerzahl: '',
                termin: { datum: '', beginn: '', ende: '' },
                termine: [{ datum: '', beginn: '', ende: '' }, { datum: '', beginn: '', ende: '' }],
                zeitraum: [
                    {
                        wochentag: '',
                        datum: { von: '', bis: '' },
                        uhrzeit: { beginn: '', ende: '' },
                    },
                ],
                wiederholung: 'Einmaliger Termin',
                material: [],
                ansprechpartner: [
                {
                    vorname: '',
                    nachname: '',
                    funktion: '',
                    telefon: '',
                    telefonpublic: false,
                    whatsapp: false,
                    email: '',
                    emailvalid: false
                },
                ],
                fotos: [],
                sonstiges: '',
                created_at: '',
                created: false,
            }
            this.success = false
        }
    }
    
}
</script>